<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ page_title }}
            .header-cta
                .flex.items-center
                    .block
                        router-link.btn-default(to="/global-territory-virtual")
                            IconSet(type="chevron-left")
                            span.m-hide.ml-3 Back

        .card__body.p-4
            .flex.gap-12
                .block(class="w-8/12")
                    .box-map-territory
                        //- .box-map-territory__search
                        //-     .field.w-full
                        //-         input.field-style(
                        //-             type="text"
                        //-             placeholder="Search location here..."
                        //-             v-model="typing_search"
                        //-         )
                        //-         .field-icon(v-if="typing_search.length < 3")
                        //-             IconSet(type="search")
                                
                        //-         button.btn-close-search(v-else @click="clearSearch()")
                        //-             IconSet(type="x")                    

                        //-     //- search result
                        //-     .box-search-result(v-if="typing_search.length >= 3 && !targetSelected")
                        //-         span.text-xs.block.pt-4.px-5 Choose a location
                        //-         ul
                        //-             li(v-for="item in location"): button(@click="itemSelected()") {{ item.name}}

                        .box-map-territory__frame
                            template(v-if="mapLoaded")
                                //- editable-map#map(v-if="vendorMap==='osm'" ref="mapRefOSM" editable :zoom="10" :center="center" :options="options" style="height: 100%; width: 100%;")
                                l-map#map(v-if="vendorMap==='osm'" ref="mapRefOSM" :zoom="10" :center="center" :options="options" style="height: 100%; width: 100%;" :editable="true")
                                    l-tile-layer(:url="url" :attribution="attribution")
                                    l-control(position="topcenter")
                                        .floating-search
                                            .search-box-map.field.relative.field--search.search-map-light
                                                .relative
                                                    input(id="search-box-map-pac-input" type="text" placeholder="Search place here (type min 3 characters)..." class="field-style" @keyup="typePlace($event.keyCode, $event.target.value)")
                                                    .field-icon(v-if="typing_search.length < 3")
                                                        IconSet(type="search")
                                                    button.btn-close-search(v-else @click="clearSearch()")
                                                        IconSet(type="x")
                                                .search-result
                                                    ul(id="autocomplete-list")

                                    //- l-circle(v-if="circleOSM.length > 0" :lat-lng="circleOSM" :radius="500" color="#000000" :weight="1.75" :opacity="1" :fillOpacity="0.25")

                                    //- l-rectangle(
                                    //- editable-rectangle(
                                    //-     :dragable="true"
                                    //-     :editable="true"
                                    //-     :bounds="rectangleOSM.bounds" 
                                    //-     :color="rectangleOSM.style.color" 
                                    //-     :weight="rectangleOSM.style.weight" 
                                    //-     :opacity="rectangleOSM.style.opacity" 
                                    //-     :fillColor="rectangleOSM.style.fillColor" 
                                    //-     :fillOpacity="rectangleOSM.style.fillOpacity" 
                                    //- )

                                gmap-map#map(v-else ref="mapRef" :center="center" :zoom="11" :options="options" style="width:100%; height: 100%;")
                            //- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253840.6529481042!2d106.68943162494352!3d-6.229386704800049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3e945e34b9d%3A0x5371bf0fdad786a2!2sJakarta!5e0!3m2!1sen!2sid!4v1656487711382!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                .block(class="w-4/12")
                    .grid.gap-8
                        .field.w-full
                            .field__label
                                label.block.text-sm.font-bold(for="field") Territory Virtual Name <sup>*</sup>
                            .field__form
                                input.field-style(type="text" placeholder="Write a name here.." v-model="name" maxlength="255")
                                .error(v-if="error_name !== null") {{ error_name }}

                        .field.w-full
                            .field__label
                                label.block.text-sm.font-bold(for="field") Case Name <sup>*</sup>
                            .field__form
                                .relative(v-if="status_case_list.status === 'loading'")
                                    .field-style
                                        .field-style--mirroring.field-style--loading
                                            Spinner(show="true" size="32")

                                .field-multiple-select
                                    multiselect(
                                        v-if="case_management_list.data && case_management_list.data.length > 0"
                                        :value="casesValue",
                                        :options="case_management_list.data ? case_management_list.data : []",
                                        :multiple="true",
                                        placeholder="Select Case",
                                        label="operation_name",
                                        track-by="id",
                                        @input="onChange"
                                    )
                                    .error(v-if="error_case_id !== null") {{ error_case_id }}


                        .field.w-full
                            .field__label
                                label.block.text-sm.font-bold Date Range <sup>*</sup>
                            .field__form
                                .relative.field-style.w-full
                                    date-range-picker(
                                        v-if="dateRange.startDate && dateRange.endDate" 
                                        ref="picker"
                                        v-model="dateRange"
                                        @update="pickerUpdate($event)" 
                                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                                        :date-format="dateFormat" 
                                        opens="left"
                                        :ranges="default_ranges"
                                        :autoApply="true"
                                    )
                                        div(
                                            slot="input" 
                                            slot-scope="picker") 
                                                | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                                    .field-icon
                                        IconSet(type="calendar")

                        .field.w-full
                            .field__label
                                label.block.text-sm.font-bold(for="field") Description
                            .field__form
                                textarea.field-style(type="text" placeholder="Write a description here.." v-model="description" style="min-height:100px")
                                .error(v-if="error_description !== null") {{ error_description }}

                        button.btn-primary.font-bold(type='submit' @click="submit") Save and Search Territory
</template>

<script>
import { encrypt } from '@/util/crypto';
import Spinner from '@/components/Spinner.vue';
import { mapState } from 'vuex';
import { env } from '@/config/env';
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import L from 'leaflet'
import { latLng } from "leaflet";
import { gmapApi } from 'vue2-google-maps';
import { LMap, LTileLayer, LRectangle, LControl, LCircle } from "vue2-leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import LDraw from 'leaflet-draw';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = ''
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
    name: 'territory-virtual-create',
    components: {
        Spinner,
        IconSet,
        DateRangePicker,
        LMap,
        LTileLayer,
        LRectangle,
        LControl,
        LCircle,
        Multiselect,
        GestureHandling,
    },

    data: function() {
        return {
            vendorMap: (typeof env.VUE_APP_VENDOR_MAP!==undefined || typeof env.VUE_APP_VENDOR_MAP!=='undefined'? env.VUE_APP_VENDOR_MAP: 'gmap'),
            // vendorMap: 'gmap',

            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: {
                territory:[],
                history:[]
            },
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),
            rectangleOSM: {
                // bounds: [[-6.18188099999998, 106.8211273227539], [-6.170255000000001, 106.83286432275388]],
                bounds: [
                    latLng(-6.18188099999998, 106.8211273227539), 
                    latLng(-6.170255000000001, 106.83286432275388)
                ],
                style: {
                    color: "#FF0000",
                    opacity: 0.8,
                    weight: 3,
                    fillColor: "#FFFFFF",
                    fillOpacity: 0.35,
                },
            },
            circleOSM: {},
            options: {
                // zoomControl: true,
                // mapTypeControl: true,
                // scaleControl: true,
                // streetViewControl: true,
                // rotateControl: true,
                // fullscreenControl: false,
                // disableDefaultUi: false
                // scrollwheel: false,
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{color: '#263c3f'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#6b9a76'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{color: '#38414e'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#212a37'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#9ca5b3'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{color: '#746855'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#1f2835'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#f3d19c'}]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{color: '#2f3948'}]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{color: '#17263c'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#515c6d'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{color: '#17263c'}]
                    }
                ],
            },

            infoWindows: [],
            mapLoaded: false,            
            center: {},
            markers: [],
            rectangle_data: {},
            rectangle_bounds: [],

            bounds: {
                north: -6.170255000000001,
                east: 106.83286432275388,
                south: -6.18188099999998,
                west: 106.8211273227539,
            },

            name: '',
            case_name: '',
            case_id: [],
            point: '',
            target: [],
            target_checked: [],
            description: '',

            // error field
            error_name: '',
            error_case_name: '',
            error_case_id: '',
            // error_point: '',
            error_target: '',
            error_description: '',

            target_keyword: '',
            target_choosen: [],
            timeout: null,   

            ImgAvatar: require('../../assets/no-image-profile.jpg'),

            places_list: [],
            place_index: '',
            place_input: '',
            object_id: '', 

            list_data: [],
            page: 1,
            total_page: null,
            next_page: false,

            openPopUp: false,
            popUpWatchlist: false,
            dateRange: {
                startDate: moment().subtract(6, 'days'),
                endDate: moment().toDate(),
            },

            default_ranges: {
                'Today': [
                    moment().set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).toDate()
                ],
                'Yesterday': [
                    moment().subtract(1, 'days').set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).subtract(1, 'days').toDate()
                ],
                'Last 7 days': [
                    moment().subtract(6, 'days').set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).toDate()
                ],
                'Last week': [
                    moment().subtract(1, 'isoWeek').startOf('isoWeek').set("hour", 0).set("minute", 0).toDate(),
                    moment().subtract(1, 'isoWeek').endOf('isoWeek').set("hour", 23).set("minute", 59).toDate()
                ],
            },

            typing_search: '',
            targetSelected: false,
        }
    },

    computed: {
        google: gmapApi,
        ...mapState({
            status_case_list: (state) => state.caseManagement.status_list,
            case_management_list: (state) => state.caseManagement.list,
        }),
        ...mapState('territoryVirtual', [
            'detail',
            'status_detail'
        ]),
        ...mapState('target', [
            'status_list',
            'list',
        ]),
        osmCenter() {
            // console.log(this.center);
            return L.latLng(this.center.lat, this.center.lng)
            // return latLng(this.center.lng, this.center.lat)
        },
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        breadcrumb() {
            let page = {
                name: 'Edit Territory Virtual',
                url: '/global-territory-virtual/'+this.id+'/edit',
            };
            if (this.route_name === 'territory-virtual-create') {
                page = {
                    name: 'Add Territory Virtual',
                    url: '/global-territory-virtual/create',
                };
            }

            return [
                {
                    name: 'Territory Virtual',
                    url: '/global-territory-virtual',
                },
                page,
            ];
        },
        page_title() {
            let title = 'Edit Territory Virtual';
            if (this.route_name === 'territory-virtual-create')
                title = 'Add Territory Virtual';
            return title;
        },
        rectangle: {
            get() {
                // console.log(Object.keys(this.rectangle_data).length);
                if (!this.rectangle_data)
                    return this.google.maps.Rectangle;

                return this.rectangle_data;
            },
            set(v) {
                this.rectangle_data = v;
            },
        },
        casesValue: {
            get() {
                if (!this.case_id) return null
                // console.log('=====onChange:get')
                // console.log(this.case_id)
                try {
                    return this.case_id.map(value => this.findOption(value.id)).filter(value => value.id)
                } catch (error) {
                    // console.log('error casesvalue')
                    // console.log(error)
                    return null
                }
            },
            set(v) {
                // console.log('=====casesValue:set')
                // console.log(v)
                this.case_id=v.map(value => {
                    let rObj = {}
                    rObj['id'] = value.id
                    return rObj
                })
            }
        },        
    },

    watch: {
        google() {},
        osmCenter() {},
        route_name() {
            this.getData();
        },
        id() {},
        breadcrumb() {},
        page_title() {},
        rectangle() {},

        // pagination for target list
        list(newData) {
            if (newData.data && newData.data.length > 0) {
                // console.log(newData.data.length)
                this.page = parseInt(newData.current_page);
                this.total_page = parseInt(newData.total_page);

                for (var i = 0; i < newData.data.length; i++) {
                    this.list_data.push(newData.data[i]);
                }
            }

        },
        target_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('target/setList', {});
        },
        target_choosen(newData) {
            // console.log('watch target_choosen');
            let target = newData;

            if (target.length > 0) {
                target = target.map(function(item) {
                    let target = {
                        id: item.id,
                        msisdn: item.msisdn,
                        label: item.label,
                    };
                    // return item;
                    return target;
                });
                // console.log(target);
            }
            
            this.target = JSON.stringify(target);
            // this.target = target;
            // console.log(this.target);
        },
        detail(newData) {
            this.name = (newData.name ? newData.name : '');
            this.case_name = (newData.case ? newData.case : '');
            this.description = (newData.description ? newData.description : '');
            this.case_id = (newData.case_id && newData.case_id ? newData.case_id : []);
            this.dateRange.startDate = (newData.from_date ? newData.from_date : moment().subtract(6, 'days'));
            this.dateRange.endDate = (newData.to_date ? newData.to_date : moment().toDate());

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm');

            if (newData.point && newData.point[0]) {
                // console.log(newData.point[0])

                let bounds = {
                    north: parseFloat(newData.point[0].north_east.lat),
                    east: parseFloat(newData.point[0].north_east.lng),
                    south: parseFloat(newData.point[0].south_west.lat),
                    west: parseFloat(newData.point[0].south_west.lng),
                };

                // let point = {
                //     "north_east": {
                //         "lat": bounds.north,
                //         "lng": bounds.east
                //     },
                //     "south_west": {
                //         "lat": bounds.south,
                //         "lng": bounds.west
                //     }
                // };

                if (this.mapLoaded && Object.keys(this.rectangle).length > 0) {
                    switch(this.vendorMap) {
                        case 'osm':
                            this.rectangle.remove();
                            // this.startMapOSM(newData.point[0]);
                            this.startMapOSM(bounds);

                            break;

                        default:
                                this.rectangle.setBounds(bounds);
                                this.mapBounds(newData.point[0]);
                            break;
                    }
                }

            }

            // target
            if (newData.target && newData.target.length > 0) {
                this.target_choosen = newData.target;

                let target_checked = [];
                for (var i = 0; i < newData.target.length; i++) {
                    // console.log(newData.target[i]);

                    for (var j = 0; j < newData.target[i].msisdn.length; j++) {
                        // console.log(newData.target[i][j]);
                        target_checked.push(newData.target[i].id + '_' + newData.target[i].msisdn[j])
                    }
                }

                // console.log(target_checked);
                this.target_checked = target_checked;
            }
        },
        name() {},
        case() {},
        case_id() {},
        point() {},
        target() {},
        target_checked() {},
        // target_checked(newData) {
        //     console.log(newData)
        // },
        description() {},
    },

    methods: {

        getDefaultImage(e) {
            e.target.src = this.ImgAvatar;
        },
        hideAllInfoWindows() {
            for (var i=0; i < this.infoWindows.length; i++) {
                this.infoWindows[i].close();
            }
        },
        datetimeFormat(datetime) {
            // return moment(datetime, 'YYYY-MM-DDThh:mm:ssZ').format('DD MMMM YYYY hh:mm');
            return moment(datetime, 'YYYY-MM-DD hh:mm:ss+0000').format('DD MMM YYYY HH:mm:ssZ');
        }, 
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },               

        mapBounds(point=null) {
            var self = this;

            switch(this.vendorMap) {
              case 'osm':
                self.$nextTick(() => {
                    let map = self.$refs.mapRefOSM;
                    if (map){
                        map = map.mapObject
                        if (map){

                            // self.bounds
                            // let bounds = [
                            //     [self.bounds.north, self.bounds.east],
                            //     [self.bounds.south, self.bounds.west]
                            // ];

                            var bounds = L.latLngBounds(
                                L.latLng(self.bounds.north, self.bounds.east),
                                L.latLng(self.bounds.south, self.bounds.west)
                            );

                            if (point !== null) {
                                bounds.extend(latLng(parseFloat(point.north_east.lat), parseFloat(point.north_east.lng)));
                                bounds.extend(latLng(parseFloat(point.south_west.lat), parseFloat(point.south_west.lng)));
                            } else {
                                bounds.extend(latLng(self.bounds.north, self.bounds.east));
                                bounds.extend(latLng(self.bounds.south, self.bounds.west));
                            }

                            map.fitBounds(bounds);
                        }
                    }
                });
                break;

              default:
                self.$nextTick(() => {
                    self.$refs.mapRef.$mapPromise.then((map) => {
                        const google = self.google;
                        var bounds = new google.maps.LatLngBounds();

                        if (point !== null) {
                            bounds.extend(new google.maps.LatLng(parseFloat(point.north_east.lat), parseFloat(point.north_east.lng)));
                            bounds.extend(new google.maps.LatLng(parseFloat(point.south_west.lat), parseFloat(point.south_west.lng)));
                        } else {
                            bounds.extend(new google.maps.LatLng(self.bounds.north, self.bounds.east));
                            bounds.extend(new google.maps.LatLng(self.bounds.south, self.bounds.west));
                        }

                        // Apply fitBounds
                        map.fitBounds(bounds); 
                    });
                });
                break;
            }

        },

        typePlace(keyCode, value) {
            // console.log('typePlace()')
            // console.log(keyCode)
            // console.log(value)

            var autocomplete_list = document.getElementById('autocomplete-list');
            autocomplete_list.innerHTML = '';

            if (keyCode === 13) {
                if (value.length < 3) {
                    this.error("Please write place keyword!");
                    return;
                }

                this.error("Please select place from keyword result!");

                // if (this.object_id.length < 0 || this.object_id === '') {
                //     this.error("Please select place from keyword result!");
                //     return;
                // }

                // this.searchPlace();

            } else {

                if (this.place_input !== value) {
                    this.places_list = [];
                    this.place_index = '';
                    this.object_id = '';

                    // clear timeout variable
                    clearTimeout(this.timeout);

                    var self = this;
                    this.timeout = setTimeout(function () {

                        if (value.length > 2) {
                            var htmlString = 
                                '<transition>'+
                                    '<svg class="spinner show m-auto" width="44" height="44" viewBox="0 0 44 44">'+
                                        '<circle class="path" fill="none" stroke-width="4" stroke-linecap="round" cx="22" cy="22" r="20"></circle>'+
                                    '</svg>'+
                                '<transition>';
                            var div = document.createElement('div');
                            div.classList.add('text-center');
                            div.innerHTML = htmlString.trim();
                            // console.log(htmlString)
                            // console.log(div)
                            let node = document.createElement("LI");
                            node.classList.add('text-center');
                            node.appendChild(div);
                            autocomplete_list.appendChild(node);

                            self.$store.dispatch('territoryVirtual/getSearchMapPlaceList', value)
                            .then((resp) => {
                                // console.log('resp on typePlace()')
                                // console.log(resp)

                                autocomplete_list.innerHTML = '';

                                if (resp.result === 'success' && resp.data.length > 0) {

                                    var data = resp.data;
                                    self.places_list = data;

                                    for (var i = 0; i < data.length; i++) {
                                        let node = document.createElement("LI");
                                        let textnode = document.createTextNode(data[i].locale_names + (data[i].administrative.length > 0 ? ', '+ data[i].administrative.reverse().join(', ') : ''));
                                        node.appendChild(textnode);
                                        // document.getElementById("autocomplete-list").appendChild(node);
                                        // console.log(node)
                                        let index = i;
                                        node.addEventListener('click', function() {
                                            // console.log(self.places_list[index])
                                            self.place_index = index;
                                            self.object_id = self.places_list[index].objectID;
                                            // console.log(self.object_id)

                                            var place_name = self.places_list[index].locale_names + (self.places_list[index].administrative.length > 0 ? ', '+ self.places_list[index].administrative.reverse().join(', ') : '');
                                            self.place_input = place_name;
                                            document.getElementById('search-box-map-pac-input').value = place_name;
                                            autocomplete_list.innerHTML = '';

                                            self.searchPlace();
                                        });

                                        autocomplete_list.appendChild(node);
                                    }

                                } else {
                                    node = document.createElement("LI");
                                    node.classList.add('text-center');
                                    let textnode = document.createTextNode("No Data Available");
                                    node.appendChild(textnode);
                                    autocomplete_list.appendChild(node);
                                }
                            });
                        }

                    // }, 650);
                    }, 3000); // 3 second
                }
            }
        },

        searchPlace() {
            this.showLoading(true);
            this.circleOSM = {};
            // console.log(this.object_id)
            this.$store.dispatch('territoryVirtual/getSearchMapPlace', this.object_id)
            .then((resp) => {
                if (resp.result === 'success' && Object.keys(resp.data).length > 0) {
                    // console.log(Array.isArray(resp.data._geoloc))
                    if (resp.data._geoloc && (resp.data._geoloc[0] || (resp.data._geoloc.lat && resp.data._geoloc.lng))) {
                        this.showLoading(false);
                        if (Array.isArray(resp.data._geoloc)) {
                            this.changePlace(resp.data._geoloc[0]);
                        } else {
                            this.changePlace({
                                lat: resp.data._geoloc.lat,
                                lng: resp.data._geoloc.lng,
                            });
                        }
                    } else {
                        this.error('Coordinate is empty!');
                    }
                } else {
                    this.error('Place not found!');
                }

            });
        },
        changePlace(coordinate) {
            // console.log('changePlace()');
            // var coordinate = {
            //     lat: -7.823217,
            //     lng: 110.383244,
            // };
            var self = this;
            console.log('changePlace')
            switch(this.vendorMap) {
              case 'osm':
                self.$nextTick(() => {
                    let map = self.$refs.mapRefOSM;
                    if (map){
                        map = map.mapObject
                        if (map){
                            // console.log(map);

                            self.circleOSM = new L.Circle([coordinate.lat, coordinate.lng], {
                                opacity: 0,
                                fillOpacity: 0,
                                radius: 500,
                            });

                            self.circleOSM.addTo(map);

                            var north_east = self.circleOSM.getBounds().getNorthEast();
                            var south_west = self.circleOSM.getBounds().getSouthWest();

                            let bounds_rectangle = {
                                north: north_east.lat,
                                east: north_east.lng,
                                south: south_west.lat,
                                west: south_west.lng,
                            };

                            self.rectangle.remove();
                            self.startMapOSM(bounds_rectangle);
                        }
                    }
                });
                break;

              default:
                self.$nextTick(() => {
                    self.$refs.mapRef.$mapPromise.then((map) => {
                        const google = self.google;

                        map.setCenter(new google.maps.LatLng(coordinate));
                        // map.setCenter(new google.maps.LatLng(coordinate.lat, coordinate.lng));


                        map.setZoom(20);

                        var distance = 500; // in meter
                        var bounds = new google.maps.LatLngBounds();
                        // console.log(bounds)

                        var north = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 0);
                        bounds.extend(north);
                        var east = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 90);
                        bounds.extend(east);
                        var south = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 180);
                        bounds.extend(south);
                        var west = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 270);
                        bounds.extend(west);
                        // console.log(north, east, south, west)


                        // var north_east = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 45);
                        // bounds.extend(north_east);
                        // var south_west = google.maps.geometry.spherical.computeOffset(map.getCenter(), distance, 225);
                        // bounds.extend(south_west);

                        // console.log(north_east, south_west)

                        let bounds_rectangle = {
                            north: north.lat(),
                            east: east.lng(),
                            south: south.lat(),
                            west: west.lng(),
                        };

                        // let bounds_rectangle = {
                        //     north: north_east.lat(),
                        //     east: north_east.lng(),
                        //     south: south_west.lat(),
                        //     west: south_west.lng(),
                        // };

                        let point = {
                            "north_east": {
                                "lat": bounds_rectangle.north,
                                "lng": bounds_rectangle.east
                            },
                            "south_west": {
                                "lat": bounds_rectangle.south,
                                "lng": bounds_rectangle.west
                            }
                        };

                        if (this.mapLoaded && Object.keys(this.rectangle).length > 0) {
                            this.rectangle.setBounds(bounds_rectangle);
                            this.mapBounds(point);
                        }

                    });
                });
                break;
            }
            console.log('changePlace2')


        },
        async startMap(set_bounds=null) {
            switch(this.vendorMap) {
              case 'osm':
                await this.startMapOSM(set_bounds);
                break;
              default:
                await this.startMapGMAP();
                break;
            }
        },
        async startMapOSM(set_bounds=null) {
            var self = this;

            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapLoaded = true;
            console.log('startOSM')
            await self.$nextTick(() => {
                let map = self.$refs.mapRefOSM;
                if (map){
                    map = map.mapObject
                    if (map){

                        const editableLayers = new window.L.FeatureGroup().addTo(map);
                        map.on(window.L.Draw.Event.CREATED, (e) => {
                            // const type = e.layerType;
                            const layer = e.layer;

                            // Do whatever else you need to. (save to db, add to map etc)
                            editableLayers.addLayer(layer);
                        });

                        // self.bounds
                        let bounds = [
                            [self.bounds.north, self.bounds.east],
                            [self.bounds.south, self.bounds.west]
                        ];

                        if (set_bounds !== null && Object.keys(set_bounds).length > 0)
                            bounds = [
                                [set_bounds.north, set_bounds.east],
                                [set_bounds.south, set_bounds.west]
                            ];
                        if (this.route_name === 'PageTerritoryVirtualEdit'){
                            self.rectangle = new L.Rectangle(bounds, {
                                color: self.rectangleOSM.style.color,
                                weight: self.rectangleOSM.style.weight,
                                opacity: self.rectangleOSM.style.opacity,
                                fillColor: self.rectangleOSM.style.fillColor,
                                fillOpacity: self.rectangleOSM.style.fillOpacity,
                                // editable: true,
                                // draggable: true,
                            });
                        } else {
                            self.rectangle = new L.Rectangle(bounds, {
                                color: self.rectangleOSM.style.color,
                                weight: self.rectangleOSM.style.weight,
                                opacity: self.rectangleOSM.style.opacity,
                                fillColor: self.rectangleOSM.style.fillColor,
                                fillOpacity: self.rectangleOSM.style.fillOpacity,
                                editable: true,
                                draggable: true,
                            });
                        }
                        self.rectangle.addTo(map);

                        map.addLayer(self.rectangle);
                        map.fitBounds(bounds);

                        // var aoiBound = {
                        //     north: 90,
                        //     west: -180,
                        //     south: -90,
                        //     east: 180
                        // };
                        self.rectangle.on("edit", function (e) {
                            // aoiBound.north = '' + e.target.getLatLngs()[0][1].lat.toFixed(4);
                            // aoiBound.west = '' + e.target.getLatLngs()[0][1].lng.toFixed(4);
                            // aoiBound.south = '' + e.target.getLatLngs()[0][3].lat.toFixed(4);
                            // aoiBound.east = '' + e.target.getLatLngs()[0][3].lng.toFixed(4);
                            // console.log({aoiBound})
                            self.showNewRect();
                        })
                        
                        self.showNewRect();
                    }
                }
            });
        },
        async startMapGMAP() {
            var self = this;
            // var mapsLatLng = [];

            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapLoaded = true;

            await self.$nextTick(() => {

                self.$refs.mapRef.$mapPromise.then((map) => {
                    const google = self.google;

                    map.center = new google.maps.LatLng(self.center);
                    if (this.route_name === 'PageTerritoryVirtualEdit'){
                        self.rectangle = new google.maps.Rectangle({
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        // fillColor: "#FF0000",
                        fillColor: "#FFF",
                        fillOpacity: 0.35,
                        // bounds: bounds,
                        bounds: self.bounds,
                        // editable: true,
                        // draggable: true,
                    });
                    } else {
                        // Define a rectangle and set its editable property to true.
                        self.rectangle = new google.maps.Rectangle({
                            strokeColor: "#FF0000",
                            strokeOpacity: 0.8,
                            strokeWeight: 3,
                            // fillColor: "#FF0000",
                            fillColor: "#FFF",
                            fillOpacity: 0.35,
                            // bounds: bounds,
                            bounds: self.bounds,
                            editable: true,
                            draggable: true,
                        });
                    }
                    self.rectangle.setMap(map);

                    // Add an event listener on the rectangle.
                    self.rectangle.addListener("bounds_changed", this.showNewRect);

                    // // Apply fitBounds
                    self.mapBounds();

                    // set map rectangle bounds
                    self.showNewRect();


                    var search_box = 
                        '<div class="search-box-map mt-3 field relative field--search search-map-light">'+
                            '<div class="relative">'+
                                '<input id="search-box-map-pac-input" type="text" placeholder="Search place here (type min 3 characters)..." class="field-style">'+
                                '<div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">'+
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>'+
                                '</div>'+
                            '</div>'+
                            '<div class="search-result">'+
                                '<ul id="autocomplete-list"></ul>'+
                            '</div>'+
                        '</div>';

                    var get_map = document.getElementById('map');

                    var el = document.createElement('div');
                    el.innerHTML = search_box;

                    
                    // use google places library -----------------------------
                    // const searchBoxInput = new google.maps.places.SearchBox(el.querySelector('#search-box-map-pac-input'));

                    // map.addListener("bounds_changed", () => {
                    //     searchBoxInput.setBounds(map.getBounds() as google.maps.LatLngBounds);
                    // });

                    // let markers = google.maps.Marker = [];

                    // Listen for the event fired when the user selects a prediction and retrieve
                    // more details for that place.
                      // searchBoxInput.addListener("places_changed", () => {
                      //   const places = searchBoxInput.getPlaces();

                      //   if (places.length == 0) {
                      //     return;
                      //   }

                      //   // Clear out the old markers.
                      //   markers.forEach(marker => {
                      //     marker.setMap(null);
                      //   });
                      //   markers = [];

                      //   // For each place, get the icon, name and location.
                      //   const bounds = new google.maps.LatLngBounds();
                      //   places.forEach(place => {
                      //     if (!place.geometry) {
                      //       console.log("Returned place contains no geometry");
                      //       return;
                      //     }
                      //     const icon = {
                      //       // url: place.icon as string,
                      //       url: place.icon,
                      //       size: new google.maps.Size(71, 71),
                      //       origin: new google.maps.Point(0, 0),
                      //       anchor: new google.maps.Point(17, 34),
                      //       scaledSize: new google.maps.Size(25, 25)
                      //     };

                      //     // Create a marker for each place.
                      //     markers.push(
                      //       new google.maps.Marker({
                      //         map,
                      //         icon,
                      //         title: place.name,
                      //         position: place.geometry.location
                      //       })
                      //     );

                      //     if (place.geometry.viewport) {
                      //       // Only geocodes have viewport.
                      //       bounds.union(place.geometry.viewport);
                      //     } else {
                      //       bounds.extend(place.geometry.location);
                      //     }
                      //   });
                      //   map.fitBounds(bounds);
                      // });

                    // end ---------------------------------------------------

                    get_map.after(el);

                    var searchBoxDiv = document.querySelector('.search-box-map');

                    // use custom api library -----------------------------
                    
                    var searchBoxPacInput = document.querySelector('#search-box-map-pac-input');
                    // console.log(searchBoxPacInput)

                    searchBoxPacInput.addEventListener('keyup', function(e) {
                        // console.log('type input')
                        // console.log(e.keyCode)
                        // console.log(e.target.value)
                        self.typePlace(e.keyCode, e.target.value)
                        // if (e.keyCode === 13) {
                        //     // action to get api here
                        //     console.log(e.target.value);
                        //     // self.clickNotification(type, data);
                        //     self.changePlace();
                        // }
                    });

                    map.controls[google.maps.ControlPosition.TOP].push(searchBoxDiv);

                    setTimeout(function(){
                        searchBoxDiv.style.display = 'inline';
                    }, 1050);
                });
            });
        },
        showNewRect() {
            const ne = this.rectangle.getBounds().getNorthEast();
            const sw = this.rectangle.getBounds().getSouthWest();

            let data_area = {
                north_east: {
                    lat: null,
                    lng: null,
                },
                south_west: {
                    lat: null,
                    lng: null,
                },
            };

            switch(this.vendorMap) {
              case 'osm':
                data_area = {
                    north_east: {
                        lat: ne.lat,
                        lng: ne.lng,
                    },
                    south_west: {
                        lat: sw.lat,
                        lng: sw.lng,
                    },
                };
                break;

              default:
                data_area = {
                    north_east: {
                        lat: ne.lat(),
                        lng: ne.lng(),
                    },
                    south_west: {
                        lat: sw.lat(),
                        lng: sw.lng(),
                    },
                };
                break;
            }

            if (this.rectangle_bounds.length === 0) {
                this.rectangle_bounds.push(data_area);
            } else {
                this.rectangle_bounds[0] = data_area;
            }

            this.point = JSON.stringify(this.rectangle_bounds);

            // console.log(this.rectangle_bounds)
            // console.log(JSON.stringify(this.rectangle_bounds))
        },
        searchTarget(nextPage=false) {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                // if (self.target_keyword.length > 2) {
                    self.$store.commit('target/setList', {});
                    if (nextPage === false) {
                        self.list_data = [];
                        self.page = 1;
                        self.total_page = null;
                        self.next_page = false;
                    } else {
                        self.next_page = true;
                    }
                    self.$store.dispatch('target/getList', [10, (nextPage === false ? self.page : (self.page + 1)), self.target_keyword]);

                // }
            }, 650);
        },
        addTarget(target) {
            target = {
                id: target.id,
                target_name: target.target_name,
                photo: target.photo,
                msisdn: target.clues, // for map msisdn[]
                label: target.label,
            };
            // console.log(target);

            if (target.msisdn.length > 0) {
                // console.log(target.msisdn);

                let search_msisdn = target.msisdn;
                search_msisdn = search_msisdn.filter(function(item) {
                    if (item.name === 'msisdn')
                        return item;
                });
                search_msisdn = Object.keys(search_msisdn).map((key) => search_msisdn[key].value);

                if (search_msisdn.length > 0) {

                    target.msisdn = search_msisdn;

                    if (this.target_choosen.length === 0) {
                        // add checked new msisdn target
                        for (var i = 0; i < target.msisdn.length; i++) {
                            this.target_checked.push(target.id + '_' + target.msisdn[i])
                        }

                        this.target_choosen.push(target);

                    } else {
                        let index_data;
                        let search_target =  this.target_choosen.filter(function(item, index) {
                            if (item.id == target.id) {
                                index_data = index;
                                return item;
                            }
                        });

                        if (search_target.length > 0) {

                            // add checkbox if any data msisdn not saved in target
                            for (var j = 0; j < target.msisdn.length; j++) {

                                let index_checked = null;
                                let search_checked = this.target_checked.filter(function(item, index) {
                                    if (item === target.id + '_' + target.msisdn[j]) {
                                        index_checked = index;
                                        return item;
                                    }
                                });

                                if (search_checked.length > 0 && index_checked !== null) {
                                    this.target_checked[index_checked] = target.id + '_' + target.msisdn[j];
                                }
                            }

                            // this.target_choosen[index_data] = target;
                            this.$set(this.target_choosen, index_data, target);

                            this.$swal.fire({
                                // icon: 'warning',
                                // title: 'Target '+ target.target_name +' already exist!',
                                icon: 'success',
                                title: 'Target '+ target.target_name +' updated!',
                            });
                        } else {
                            // add checked new msisdn target
                            for (var k = 0; k < target.msisdn.length; k++) {
                                this.target_checked.push(target.id + '_' + target.msisdn[k])
                            }

                            this.target_choosen.push(target);
                        }
                    }
                } else {
                    this.error("Target don't have MSISDN!");
                }
            } else {
                this.error("Target don't have MSISDN!");
            }
            if (this.target_keyword.length > 0) {
                this.target_keyword = '';
                this.list_data = [];
                this.page = 1;
                this.total_page = null;
                this.next_page = false;
                this.searchTarget();                
            } else {
                this.target_keyword = '';
            }

            if (document.getElementById('find-target-input'))
                document.getElementById('find-target-input').focus();
        },
        removeTarget(index) {
            for (var i = 0; i < this.target_choosen.length; i++) {
                if (index === i) {

                    // remove target checked if target removed
                    let target_remove = this.target_choosen[index];
                    for (var j = 0; j < target_remove.msisdn.length; j++) {
                        let index_checked = null;
                        let search_checked = this.target_checked.filter(function(item, index) {
                            if (item === target_remove.id + '_' + target_remove.msisdn[j]) {
                                index_checked = index;
                                return item;
                            }
                        });

                        if (search_checked.length > 0 && index_checked !== null) {
                            this.target_checked.splice(index_checked, 1);
                        }
                    }

                    this.target_choosen.splice(index, 1);
                }
            }
        },

        checkTargetMsisdn(e, target_id, msisdn) {
            let check_target = this.target_checked;
            check_target = check_target.filter(function(item) {
                let split_item = item.split('_');
                if (split_item[0] === target_id)
                    return item;
            });

            if (check_target.length === 0) {
                this.target_checked.push(target_id + '_' + msisdn);
                e.target.checked = true;

                this.$swal.fire({
                    icon: 'warning',
                    title: 'Target need min 1 MSISDN!',
                    // allowOutsideClick: false,
                });
                // return;
            }
        },
        async pickerUpdate(e) {
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRangeTV']) {
                // let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                // const default_date = this.$store.getters['config/getDefaultDate'](def);
                
                this.dateRange.startDate = moment(this.from_date + ' 00:00', 'YYYY-MM-DD HH:mm').toDate();
                this.dateRange.endDate = moment(this.to_date + ' 23:59', 'YYYY-MM-DD HH:mm').toDate();
                
                // this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm');
                // this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRangeTV'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

            } else {
                this.from_date = moment(this.dateRange.startDate).set("hour", 0).set("minute", 0).format('YYYY-MM-DD HH:mm');
                this.to_date = moment(this.dateRange.endDate).set("hour", 23).set("minute", 59).format('YYYY-MM-DD HH:mm');
                // this.getData();
            }
        },        
        getData() {
            this.name = '';
            this.case_name = '';
            // this.point = '';
            this.target = '';
            this.description = '';
            
            this.target_keyword = '';
            this.target_choosen = [];
            this.$store.commit('target/setList', {});

            // pagination target list
            this.list_data = [];
            this.page = 1;
            this.total_page = null;
            this.next_page = false;

            this.error_name = '';
            this.error_case_name = '';
            this.error_case_id= '';
            // this.error_point = '';
            this.error_target = '';
            this.error_description = '';

            // console.log(this.route_name);
            if (this.route_name === 'territory-virtual-edit') {
                EventEmit.$emit('showLoading', true);
                this.$store.dispatch('caseManagement/getList', [100, 1, null, 'active']);
                this.$store.dispatch('territoryVirtual/getDetail', this.id)
                .then((resp) => {
                    if (resp === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });                    
                
            } else {

            if (this.route_name === 'territory-virtual-create') {
                this.$store.commit('territoryVirtual/setDetail', {});
                this.$store.dispatch('caseManagement/getList', [100, 1, null, 'active']);
                // get first list target
                this.searchTarget();

                // set map to default
                if (this.mapLoaded && Object.keys(this.rectangle).length > 0) {
                    switch(this.vendorMap) {
                        case 'osm':
                        this.rectangle.remove();
                        this.startMapOSM();

                        // var bounds = [
                        //     [this.bounds.north, this.bounds.east],
                        //     [this.bounds.south, this.bounds.west]
                        // ];

                        // // console.log(this.rectangle);
                        // this.rectangle.setBounds(bounds);

                        // // this.rectangle.reset();
                        // // this.rectangle.update();

                        // this.mapBounds();
                        break;

                        default:
                        this.rectangle.setBounds(this.bounds);
                        this.mapBounds();
                        break;
                    }
                }
            }}
            
        },
        reset() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset form?',
                // text: id,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                // console.log('on result');

                if (result.isConfirmed) {
                    if (this.route_name === 'territory-virtual-create') {
                        this.name = '';
                        this.case_name = '';
                        // this.point = '';
                        this.target = '';
                        this.description = '';

                        this.target_keyword = '';
                        this.target_choosen = [];
                        this.$store.commit('target/setList', {});

                        this.error_name = '';
                        this.error_case_name = '';
                        this.error_case_id= '';
                        // this.error_point = '';
                        this.error_target = '';
                        this.error_description = '';

                        // console.log(this.rectangle.bounds)
                        // this.rectangle.bounds = this.bounds;
                        this.rectangle.setBounds(this.bounds);
                        
                    } else {
                        this.getData();
                    }
                }
            });
        },
        submit(e, save_continue=false){
            e.preventDefault();
            // console.log(e);
            // console.log(save_continue);

            this.showLoading(true);

            this.error_name = '';
            this.error_case_name = '';
            this.error_case_id= '';
            // this.error_point = '';
            this.error_target = '';
            this.error_description = '';

            // validation
            if (this.name.length==0) {

                this.error_name = (this.name.length==0 ? 'Field name is required.' : '');

                this.$swal.fire({
                    icon: 'warning',
                    title: 'Data is required!',
                    text: 'Name is required!',
                    // timer: 3000,
                });

                return;
            }

            if (this.name.length>255) {

                this.error_name = (this.name.length>255 ? 'Field name is not more than 255 characters.' : '');

                this.$swal.fire({
                    icon: 'warning',
                    title: 'Data length is not more than 255 characters!',
                    text: 'Name is not allowed more than 255 characters!',
                    // timer: 3000,
                });

                return;
            }        

            let target = JSON.parse(this.target);
            let target_choosen = this.target_choosen;

            for (var i = 0; i < target_choosen.length; i++) {
                let target_choosen_id = target_choosen[i].id;
                let target_checked = this.target_checked;
                target_checked = target_checked.filter(function(item) {
                    let split_item_msisdn = item.split('_');
                    if (split_item_msisdn[0] === target_choosen_id)
                        return item;
                });

                if (target_checked.length > 0)
                    target_checked = Object.keys(target_checked).map((key) => target_checked[key].split('_')[1]);

                // console.log(target_checked);
                target[i].msisdn = target_checked
            }

            this.target = JSON.stringify(target);
            let cases = JSON.stringify(this.case_id);

            // console.log(this.target);

            /* eslint-disable no-unreachable */
            // return;

            if (this.route_name === 'territory-virtual-create') {

                this.$store.dispatch('territoryVirtual/create', [this.name, cases, this.point, this.description, this.from_date, this.to_date])
                .then((resp) => {
                    if (resp.result === 'success') {
                        this.$router.push('/global-territory-virtual/'+ resp.data.data.id +'/detail/'+encrypt(JSON.stringify({'name':resp.data.data.name,'from_date':resp.data.data.from_date,'to_date':resp.data.data.to_date})));
                        

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });
                    } else {
                        
                        if (resp.data.message && resp.data.message.errors) {
                            this.error_name = (resp.data.message.errors.name ? resp.data.message.errors.name : '');
                            this.error_case_id = (resp.data.message.errors.case_id ? resp.data.message.errors.case_id : '');
                            this.error_case_name = (resp.data.message.errors.case_name ? resp.data.message.errors.case_name : '');
                            this.error_target = (resp.data.message.errors.target ? resp.data.message.errors.target : '');
                            this.error_description = (resp.data.message.errors.description ? resp.data.message.errors.description : '');

                            this.error(null, resp.data.message.errors);
                        } else {
                            this.error();
                        }
                    }
                });

            // PageTerritoryVirtualEdit
            } else {

                this.$store.dispatch('territoryVirtual/update', [this.id, this.name, cases, this.point, this.description, this.from_date, this.to_date])
                .then((resp) => {
                    if (resp.result === 'success') {
                        this.$router.push('/global-territory-virtual/'+ this.id +'/detail/'+encrypt(JSON.stringify({'name':this.name,'from_date':this.from_date,'to_date':this.to_date})));
                        
                        
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });
                    } else {

                        if (resp.data.message && resp.data.message.errors) {
                            this.error_name = (resp.data.message.errors.name ? resp.data.message.errors.name : '');
                            this.error_case_name = (resp.data.message.errors.case_name ? resp.data.message.errors.case_name : '');
                            this.error_target = (resp.data.message.errors.target ? resp.data.message.errors.target : '');
                            this.error_description = (resp.data.message.errors.description ? resp.data.message.errors.description : '');

                            this.error(null, resp.data.message.errors);
                        } else {
                            this.error();
                        }
                    }
                });
            }
        },
        showLoading(show=true) {
            if (show) {
                this.$swal.fire({
                    title: '',
                    html: '<div class="save_loading"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div><div><h4>Loading...</h4></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });

            } else {
                this.$swal.close();
            }
        },
        error(message=null, data=null) {
            let errors = '';

            if (data !== null) {
                for (var k in data){
                    if (Object.prototype.hasOwnProperty.call(data, k)) {
                        // alert("Key is " + k + ", value is " + data[k]);
                        errors += this.capitalize(k) + ": " + data[k] + '<br><br>';
                        // errors += '<div class="swal2-html-container" style="display: block;">' + data[item] + '<div>';
                    }
                }

                // Object.keys(data).forEach(function (item) {
                //     errors += data[item] + '<br><br>';
                //     errors += '<div class="swal2-html-container" style="display: block;">' + data[item] + '<div>';
                // });
            }

            this.$swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: (message!==null ? message : 'Something wrong.'),
                html: errors,
                // timer: 3000
            });
        },

        capitalize(s) {
            if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1)
        },

        clearSearch() {
            this.typing_search = ''
            this.targetSelected = false
        },
        
        defaultFilter(){
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD HH:mm').toDate();
            this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD HH:mm').toDate();
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.user_keyword=''
            this.user_id=null
            this.items_per_page=10
        },  
        onChange (value) {
            this.casesValue = value
        },      
        findOption(value) {
            // console.log('=====findOption')
            // console.log(value)
            const opt = this.case_management_list.data
            try {
                return opt.find(option => option['id'] === value)
            } catch (error) {
                // console.log('error')
                // console.log(error)
                return opt
            }
        },

    },
    async mounted() {
        let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
        const default_date = this.$store.getters['config/getDefaultDate'](def);

        this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD HH:mm').toDate();
        this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD HH:mm').toDate();

        this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm');
        this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm');

        this.$store.commit('target/setStatusList', { cancel: null, status: false });
        await this.startMap();
        await this.getData();
    },

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="sass">
.box-map-territory
    @apply w-full  
    background-color: black
    position: relative
    &__search
        width: 100%
        max-width: 400px
        position: absolute
        z-index: 9
        top: 16px
        left: 50%
        margin-left: -200px
    &__frame
        width: 100%
        height: 650px
        position: relative
        iframe
            @apply absolute inset-0 w-full h-full
        
        .leaflet-control
            float: none

        input
            width: 450px !important

        ul
            li
                font-size: 13px !important
                padding: 8px 16px !important
    
</style>